.upload-clothes-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: teal;
}

.back-button:hover {
  color: #016d6d;
}

.upload-segment {
  margin-top: 20px !important;
  padding: 30px !important;
}

.upload-preview-container {
  width: 100%;
  height: 300px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.upload-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.upload-placeholder {
  text-align: center;
  color: #888;
}

.upload-button {
  margin: 20px 0 !important;
}

.ui.form input {
  margin: 10px 0;
}

.ui.message {
  margin: 10px 0;
} 