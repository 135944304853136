.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* You can adjust this color as needed */
}

.enter-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://res.cloudinary.com/sheyou/image/upload/v1727875894/virtual_closet_5d66ccc54e.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cover-container {
  max-width: 90%;
  max-height: 90%;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
  color: #333;
}

.enter-button {
  z-index: 1;
}
