.enter-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url('https://res.cloudinary.com/sheyou/image/upload/v1727875894/virtual_closet_5d66ccc54e.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0;
  position: relative;
}

.welcome-text {
  font-size: 3rem;
  color: orange;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.enter-button {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.enter-button:hover {
  background-color: #009B92 !important;
}

.login-button {
  position: absolute;
  bottom: 100px;
  /* left: 50%;
  transform: translateX(-50%); */
  z-index: 1;
}

.login-button:hover {
  background-color: #FFD700 !important; /* Darker shade of yellow for hover */
}

.success-message {
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  min-width: 300px;
  text-align: center;
}
