.look-book {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  background-color: #f5f5dd;
}

.look-icon-container {
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  width: 90vw;
  z-index: 1000;
}

/* ... (keep existing styles) */

.content-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px); /* Adjust for header space */
  gap: 20px; /* Add space between sections */
}

.card-container {
  flex: 1;
  width: 100vw;
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh; /* Limit to roughly half the viewport height */
}

.calendar-container {
  height: 40vh;
  min-height: 300px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 20px;
  overflow-y: hidden;
  overflow-x: auto;
}

/* Force the grid to take full width */
.calendar-container .ui.grid {
  width: 100% !important;
  margin: 0 !important;
  display: flex !important;
}

.calendar-container .ui.grid > .row {
  display: flex !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
}

.calendar-container .ui.grid > .row > .column {
  flex: 1 0 auto !important;
  width: calc(100% / 7) !important;
  min-width: 160px !important;
  padding: 0 8px !important;
}

.calendar-day {
  text-align: center;
  border-right: 1px solid #ddd;
  height: 100%;
}

.calendar-day:last-child {
  border-right: none;
}

.day-header {
  font-weight: bold;
  margin-bottom: 5px;
  padding: 5px;
  background-color: rgba(0, 181, 173, 0.1);
  border-radius: 4px;
}

.day-content {
  height: calc(40vh - 60px); /* Adjust height based on calendar container */
  min-height: 240px; /* Ensure minimum height for content */
  background-color: transparent;
  border: 1px dashed #ddd;
  padding: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduled-look {
  height: calc(100% - 20px);
  max-height: calc(40vh - 80px);
  min-height: 220px;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  font-size: 0.9em;
}

.scheduled-look p {
  margin: 0 0 8px 0 !important;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  order: -1;
}

.scheduled-look .look-preview {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  flex-shrink: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-container {
    height: calc(100vh - 100px);
  }

  /* .calendar-container {
    height: 120px;
  } */

  /* .day-content {
    height: 80px;
  } */
}

.look-card img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
}

.look-card .content {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0.7rem !important;
  position: relative !important;
}

.look-card .description {
  position: absolute !important;
  bottom: 0.7rem !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  font-size: 0.85em !important;
}

.look-card .meta {
  margin-bottom: 0.3rem !important;
  font-size: 0.85em !important;
}

.look-card .header {
  margin: 0 !important;
  flex: 1;
  padding-right: 1rem;
  font-size: 1em !important;
  margin-bottom: 0.3rem !important;
}

.look-card {
  cursor: move;
  min-height: 280px !important;  /* Reduced from 320px */
  width: 220px !important;      /* Reduced from 250px */
  margin: 0.8rem !important;    /* Reduced from 1rem */
  display: flex !important;
  flex-direction: column !important;
}

.look-preview {
  position: relative !important;
  width: 100px !important;     /* Reduced from 120px */
  height: 100px !important;    /* Reduced from 120px */
  margin: 0.7rem auto !important;
  background-color: transparent !important;
}

.look-preview img {
  position: absolute !important;
  object-fit: contain !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.card-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card-container .ui.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;  /* Reduced gap */
}

.card-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.card-icons {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.schedule-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
  font-size: 1em !important;
}

.schedule-icon:hover {
  transform: scale(1.1);
}

.schedule-dropdown {
  position: relative;
}

.schedule-dropdown .menu {
  min-width: 120px !important;
}

.schedule-dropdown .item {
  padding: 0.5rem 1rem !important;
}

.delete-icon {
  font-size: 1em !important;
}

.upload-clothes-container .ui.button {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-clothes-container .ui.button i.icon {
  margin: 0 0.5rem 0 0;
}

@media (max-width: 768px) {
  .upload-clothes-container .ui.button {
    padding: 0.8em 1em;
    font-size: 0.9em;
  }
  
  .upload-clothes-container .ui.button i.icon {
    margin: 0 0.3rem 0 0;
  }
}
