.my-closet {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.sticky-wrapper {
  position: sticky;
  top: 0;
  height: 100vh;
  flex-shrink: 0;
  width: 50%; /* Default to 50% for screens < 600px */
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  justify-content: space-between; /* Add this */
}

.sticky-placeholder {
  width: 100%;
  height: calc(100vh); /* Increased space at top for buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #F5F5DD;
  position: relative;
  z-index: 1;
  padding-top: 15px; /* Add space below the buttons */
}

.placeholder-image {
  height: 70vh; /* Fixed height */
  width: 50%;
  object-fit: fill !important; /* Force fill to override any inline styles */
  z-index: 1;
  user-select: none;
  -webkit-user-drag: none;
}

.uploaded-avatar-image {
  max-height: 70vh;
  max-width: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  z-index: 1;
  user-select: none;
  -webkit-user-drag: none;
}

.dropped-items-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 140px); /* Adjust to match new height */
  z-index: 2;
}

.dragged-item-container {
  position: absolute;
  cursor: move;
  transform-origin: center center;
  transition: transform 0.05s ease-out, z-index 0s;
  pointer-events: auto;
}

.resize-handles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.resize-handle {
  position: absolute;
  background: #00b5ad;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;
  pointer-events: auto;
}

/* Corner handles (proportional resize) */
.resize-handle.corner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* Edge handles (single-axis resize) */
.resize-handle.edge {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

/* Position corner handles */
.resize-handle.corner.top-left { top: -6px; left: -6px; cursor: nw-resize; }
.resize-handle.corner.top-right { top: -6px; right: -6px; cursor: ne-resize; }
.resize-handle.corner.bottom-left { bottom: -6px; left: -6px; cursor: sw-resize; }
.resize-handle.corner.bottom-right { bottom: -6px; right: -6px; cursor: se-resize; }

/* Position edge handles */
.resize-handle.edge.left { left: -4px; top: 50%; transform: translateY(-50%); cursor: e-resize; }
.resize-handle.edge.right { right: -4px; top: 50%; transform: translateY(-50%); cursor: e-resize; }
.resize-handle.edge.top { top: -4px; left: 50%; transform: translateX(-50%); cursor: n-resize; }
.resize-handle.edge.bottom { bottom: -4px; left: 50%; transform: translateX(-50%); cursor: n-resize; }

/* Hover effects */
.resize-handle:hover {
  background: #009c94;
}

.resize-handle.corner:hover {
  transform: scale(1.2);
}

.resize-handle.edge.left:hover,
.resize-handle.edge.right:hover {
  transform: translateY(-50%) scale(1.2, 1);
}

.resize-handle.edge.top:hover,
.resize-handle.edge.bottom:hover {
  transform: translateX(-50%) scale(1, 1.2);
}

.rotate-handle {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #00b5ad;
  border: 2px solid white;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  cursor: grab;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;
  pointer-events: auto;
}

.rotate-handle:hover {
  transform: translateX(-50%) scale(1.1);
  background: #009c94;
}

.rotate-handle:active {
  cursor: grabbing;
}

.dragged-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  user-select: none;
  -webkit-user-drag: none;
}

.dragged-item-container.selected {
  border: 2px solid #00BAB0;
  box-shadow: 0 0 10px rgba(33, 133, 208, 0.5);
  position: relative;
}

.dragged-item-container.selected::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #00BAB0;
  box-shadow: 0 0 10px rgba(33, 133, 208, 0.5);
  pointer-events: none;
}

.move-icon-container {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  padding: 5px;
  cursor: move;
}

.resize-icon-container {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  cursor: se-resize;
  z-index: 3;
}

.scrollable-cards.ui.segment {
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  margin: 0;
  border-radius: 0;
  border-left: 1px solid rgba(34,36,38,.15);
}

.ui.card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 290px !important;  /* Fixed width for all cards */
  margin: 1rem !important;  /* Consistent margin */
}

.ui.card .card-image {
  height: 290px !important;  /* Fixed height for images */
  object-fit: contain;  /* Maintain aspect ratio without stretching */
  background-color: #f9f9f9;  /* Light background for empty spaces */
  padding: 10px;
}

.ui.card .content {
  height: 120px !important;  /* Fixed height for content area */
  overflow: hidden;  /* Hide overflow content */
}

.ui.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;  /* Consistent gap between cards */
}

.ui.card:hover {
  transform: scale(1.05);
}

@media (min-width: 600px) {
  .sticky-wrapper {
    width: 400px;
  }

  .scrollable-cards.ui.segment {
    width: calc(100% - 400px);
  }

 
}

/* Responsive adjustments */
/* @media (max-width: 1200px) {
  .ui.card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .ui.card {
    width: calc(100% - 20px);
  }
} */

.resize-horizontal-icon-container {
  position: absolute;
  padding: 5px;
  cursor: ew-resize;
  z-index: 3; /* Ensure the icon is above the image */
}

.resize-horizontal-icon-container.top-right {
  top: 5px;
  right: 5px;
}

.resize-horizontal-icon-container.bottom-right {
  bottom: 5px;
  right: 5px;
}

.resize-vertical-icon-container {
  position: absolute;
  padding: 5px;
  cursor: ns-resize;
  z-index: 3; /* Ensure the icon is above the image */
}

.resize-vertical-icon-container.bottom-right {
  bottom: 5px;
  right: 5px;
}

.button-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  z-index: 10;
  gap: 10px; /* Add spacing between buttons */
}

.button-container .ui.button {
  flex: 1;
  margin: 0;
  position: relative;
  z-index: 11;
}

/* Style for the delete all button to make it more compact */
.button-container .ui.button[title="Clear All Items"] {
  flex: 0 0 auto; /* Don't grow, don't shrink, auto basis */
  width: 40px; /* Fixed width for the icon button */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
  width: calc(100% - 40px);
}

.upload-avatar-button {
  font-size: 0.9em !important;
  padding: 10px 15px !important;
  height: fit-content;
  white-space: nowrap;
}

@media (min-width: 600px) {
  .icon-container {
    width: 360px;
  }
}

.home-icon, .folder-icon {
  cursor: pointer;
  font-size: 24px; /* Make icons larger */
  color: teal; /* Ensure color is visible */
}

.avatar-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reset-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.reset-icon:hover {
  transform: rotate(90deg);
}

.scrollable-cards .icon {
  transition: transform 0.2s ease;
}

.scrollable-cards .icon:hover {
  transform: scale(1.1);
}

.dropped-item-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.dragged-item-container {
  pointer-events: auto;
}

.resize-handle, .rotate-handle {
  pointer-events: auto;
}

.avatar-controls-container {
  position: absolute;
  top: 70px;
  left: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  padding: 0 20px;
}

.avatar-sliders {
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.slider-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.slider-container label {
  min-width: 80px;
  font-weight: bold;
}

.avatar-slider {
  flex: 1;
}

.color-picker-popover {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Style the range input */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #ddd;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #00b5ad;
  cursor: pointer;
  transition: all 0.2s ease;
}

input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

/* Add some spacing between sliders and buttons */
.button-container {
  margin-top: 20px;
}

.avatar-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vertical-divider {
  color: #666;
  padding: 0 8px;
  position: relative;
}

.vertical-divider::before,
.vertical-divider::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 1px;
  height: 8px;
  background-color: #ccc;
}

.vertical-divider::before {
  top: -2px;
}

.vertical-divider::after {
  bottom: -2px;
}

.edit-avatar-button {
  padding: 8px !important;
  margin: 0 !important;
}

.avatar-controls-container {
  position: absolute;
  top: 70px; /* Position below the top controls */
  left: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.avatar-sliders {
  background: rgba(255, 255, 255, 0.95);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 181, 173, 0.2);
}

/* Update existing styles */
.upload-avatar-button {
  font-size: 0.9em !important;
  padding: 8px 15px !important;
  height: fit-content;
  white-space: nowrap;
}

/* Add this new class for proportional resize */
.dragged-image.proportional {
  object-fit: contain;
}


